<template>
    <div class="page-warning-probe-list">
        <Nav />
        <div class="header">
            <div class="title">返厂维护/异常探头记录</div>
            <div class="header-right">
                <span class="btn" @click="switchAccount">切换登录</span>
                <!-- <span class="btn add-record" @click="handleClick">新建记录</span> -->
            </div>
        </div>
        <div :class="{'content': true}">
            <div class="filter-box">
                <el-row :gutter="24">
                    <div class="header-tab">
                        <div class="check-count-list">
                            <div class="list-item" v-for="(item, index) in data" :key="index">
                                <div class="item-title">{{item.tagName}}</div>
                                <span>总数：{{item.totalSize}}个</span>
                                <span>已验收：{{item.checkedSize}}个</span>
                                <span>剩余：{{item.totalSize - item.checkedSize}}个</span>
                            </div>
                        </div>

                        <div class="right-btn">
                            <!-- <div class="warning-probe-btn clear-warning-btn" v-if="isShowSureBtn" @click="onclickSureBtn">核对验收</div> -->
                            <div class="warning-probe-btn" @click="isShowHistory = true">验收记录</div>
                        </div>
                    </div>
                </el-row>
                <el-row :gutter="24">
                    <el-form label-width="90px" center>
                        <el-col :xs="24" :sm="12" :md="14" :lg="14" :xl="14">
                            <el-form-item label="上传时间:" label-width="72px">
                                <el-col :span="11">
                                    <el-form-item prop="startTime">
                                        <el-date-picker type="date" @change="search(1)" placeholder="开始时间" v-model="startTime" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col class="line" :span="1">-</el-col>
                                <el-col :span="11">
                                    <el-form-item prop="endTime">
                                        <el-date-picker type="date" @change="search(1)" placeholder="结束时间" v-model="endTime" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
            </div>
            <div class="table-body">
                <el-table
                    :data="list"
                    style="width: 100%">
                    <el-table-column
                    label="探头数量"
                    prop="size">
                    </el-table-column>
                    <el-table-column
                    label="故障类型"
                    prop="tagName">
                    </el-table-column>

                    <el-table-column
                    label="上传时间"
                    prop="time">
                    </el-table-column>

                    <el-table-column
                    label="查看">
                        <template slot-scope="scope">
                            <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">编辑 </el-button> -->
                            <el-button @click="openImgModal(scope.row)" type="text" size="small">查看 </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @size-change="onChangePageSize"
                    @current-change="onChangePageNo"
                    :page-no.sync="pageOptions.pageNo"
                    :page-sizes="pageOptions.pageSizes"
                    :page-size="pageOptions.pageSize"
                    center
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageOptions.total">
                </el-pagination>
            </div>
        </div>
        <div class="footer">
            <img :src="ossHost + 'img/logo.png'" alt="">
        </div>

        <el-dialog title="请输入此次验收的探头数量"
            :visible.sync="isShowSureModal"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            @close="onCancel"
            center>
            <el-form style="width: 70%;margin: 0 auto">
                <el-form-item label="验收故障类型:" label-width="100px">
                    <el-select v-model="checkTagId" placeholder="请选择">
                        <el-option v-for="item in tagList" :key="item.id" :label="item.tagName == '全部' ? '请选择' : item.tagName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="请输入数量:" label-width="100px">
                    <el-input placeholder="请输入数量" center v-model="imgNum"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isShowSureModal = false" :loading="loading">取 消</el-button>
                <el-button type="primary" @click="onConfirmSelect" :disabled="!(imgNum > 0 && checkTagId)" :loading="loading">确认</el-button>
            </div>
        </el-dialog>

        <el-dialog title="验收记录"
            :visible.sync="isShowHistory"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            center>
            <div class="table-body">
                <el-table
                    :data="scrapProbList"
                    style="width: 100%">
                    <el-table-column
                    label="故障类型"
                    prop="tagName">
                    </el-table-column>
                    <el-table-column
                    label="数量"
                    prop="size">
                    </el-table-column>
                    <el-table-column
                    label="时间"
                    prop="time">
                    </el-table-column>
                </el-table>
                <el-pagination
                    @size-change="onChangeHistoryPageSize"
                    @current-change="onChangeHistoryPageNo"
                    :page-no.sync="historyPageOptions.pageNo"
                    :page-sizes="historyPageOptions.pageSizes"
                    :page-size="historyPageOptions.pageSize"
                    center
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="historyPageOptions.total">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isShowHistory = false">关闭</el-button>
            </div>
        </el-dialog>

        <el-dialog title="查看图片"
            :visible.sync="isShowImgModal"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            center>
            <div class="img-modal-body">
                <div class="modal-header"><span>{{selectItem.tagName}}</span>  {{selectItem.time}}</div>
                <div class="model-img-list">
                    <img :src="item" v-for="(item, index) in selectItem.images" @click="openImg(item)" :key="index" alt="">
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isShowImgModal = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { getYMD } from '@/utils/time'
import Nav from '@/components/nav'
export default {
    name: "RyWarningProbeList",
    components: {
        Nav
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            startTime: '',
            endTime: '',
            number: '',
            list: [],
            scrapProbList: [],
            data: {},
            isShowModal: false,
            formLabelWidth: '80px',
            pageOptions: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 10,
                total: 0,
                pageNo: 1
            },
            historyPageOptions: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 10,
                total: 0,
                pageNo: 1
            },
            loading: false,
            isShowSureModal: false,
            sureResponsibleModel: '',
            imgNum: '',
            isShowHistory: false,
            isShowImgModal: false,
            selectItem: {},
            tagList: [],
            checkTagId: ''
        };
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        const name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid')

        const fType = vm.$strTool.filterFactoryType(uuid);
        vm.name = name;
        if (vm.$config.base.fList.indexOf(fType) > -1) {
            (fType != 'yingwo' || !name) && vm.$router.replace({
                path: "/ryProbe/afterSale/login"
            });
        } else {
            vm.$router.replace({
                path: "404"
            });
        }

        vm.pageNo = 1;
        vm.historyPageNo = 1;
        vm.pageSize = 10;
        vm.historyPageSize = 10;
        vm.loadTag(true)
    },
    computed: {
        isShowSureBtn() {
            const vm = this;
            const { USER_NAME } = vm.$config.keys;
            const name = vm.$localStorage.getItem(USER_NAME);
            return name == 'Yudada'
        }
    },
    methods: {
        loadTag(isResult) {
            const vm = this;
            const params = {}
            !isResult ? (params.type = 6) : (params.handlingType = 2)
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}${isResult ? 'fc/careInfo/tags' : 'returnFac/tags'}`,
                params
            }).then(res => {
                vm.tagList = [{
                        id: '',
                        tagName: "全部"
                    }].concat(res.data)
                vm.loadCheckList();
                vm.loadCheckHistory();
            });
        },
        onConfirmSelect() {
            const vm = this
            vm.isShowSureModal = false
            if (!(vm.imgNum > 0)) {
                Toast.fail('请填写数量')
                return
            }
            Dialog.confirm({
                title: "提示",
                message: `确定验收吗？`,
                confirmButtonColor: "#32BE32"
            })
            .then(() => {
                vm.$http({
                    type: "POST",
                    url: `${vm.$config.base.PROBE_BASE_URL}scrapProbe/check`,
                    data: {
                        size: vm.imgNum,
                        password: vm.$config.base.password,
                        tagId: vm.checkTagId
                    }
                }).then((res) => {
                    if (res.code != 1) {
                        Toast.fail(res.message || '验收失败')
                        return
                    }
                    Toast.success({
                        message: '验收成功'
                    })
                    vm.imgNum = ''
                    vm.checkTagId = ''
                    vm.loadCheckList()
                }).catch(err => {
                    vm.loading = false;
                    console.log(err)
                });
            })
            .catch(() => {
                // on cancel
            });
        },

        onclickSelect(model) {
            this.sureResponsibleModel = model;
        },

        switchAccount() {
            const vm = this;
            Dialog.confirm({
                title: "提示",
                message: "是否确认退出当前账号？",
                confirmButtonColor: "#32BE32"
            })
            .then(() => {
                const { USER_NAME } = vm.$config.keys;
                vm.$localStorage.removeItem(USER_NAME);
                vm.$router.replace({
                    path: "/ryProbe/afterSale/login"
                });
            })
            .catch(() => {
                // on cancel
            });
        },

        search(type) {
            const vm = this;
            let err = ''
            if (vm.number.length < 5 && type != 1) {
                err = '请输入正确的ID'
            }

            if (type == 1 && (vm.startTime && vm.endTime)) {
                // !(vm.startTime && vm.endTime) && (err = '请选择开始跟结束时间')
                new Date(vm.endTime).getTime() < new Date(vm.startTime).getTime() && (err = '结束时间不能早于开始时间')
            }

            if (err) {
                Toast.fail({
                    message: err
                })
                return
            }
            vm.loadCheckList();
        },
        onChangePageNo(pageNo) {
            this.pageOptions.pageNo = pageNo
            this.loadCheckList()
        },
        onChangeHistoryPageNo(pageNo) {
            this.historyPageOptions.pageNo = pageNo
            this.loadCheckHistory()
        },

        onChangeHistoryPageSize(pageSize) {
            this.historyPageOptions.pageSize = pageSize
            this.loadCheckHistory()
        },

        onChangePageSize(pageSize) {
            this.pageOptions.pageSize = pageSize
            this.loadCheckList()
        },
        loadCheckList() {
            const vm = this;
            const params = {
                pageNo: vm.pageOptions.pageNo,
                pageSize: vm.pageOptions.pageSize
            }

            if (vm.startTime && vm.endTime) {
                params.startAt = new Date(vm.startTime).getTime() / 1000
                params.endAt = new Date(vm.endTime).getTime() / 1000 + 3600 * 24 - 1
            }

            vm.loading = true;
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}scrapProbe/list`,
                params,
                except: true
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                vm.loading = false;
                res.data.data.map(item => {
                    const tagItem = vm.tagList.filter(tItem => tItem.id == item.tagId)[0]
                    item.tagName = tagItem ? tagItem.tagName : '-'
                    item.time = getYMD(item.uploadAt);
                })
                vm.list = res.data.data;
                vm.pageOptions.total = res.data.total
                vm.pageOptions.pageNo = vm.pageNo;
                vm.loadTotalData()
            });
        },

        loadTotalData() {
            const vm = this;
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}scrapProbe/count`
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                res.data.map(item => {
                    const tagItem = vm.tagList.filter(tItem => tItem.id == item.tagId)[0]
                    item.tagName = tagItem ? tagItem.tagName : '-'
                })
                vm.data = res.data
            });
        },

        loadCheckHistory() {
            const vm = this;
            const params = {
                pageNo: vm.historyPageOptions.pageNo,
                pageSize: vm.historyPageOptions.pageSize
            }

            vm.loading = true;
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}scrapProbe/check`,
                params
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                vm.loading = false;
                res.data.data.map(item => {
                    const tagItem = vm.tagList.filter(tItem => tItem.id == item.tagId)[0]
                    item.tagName = tagItem ? tagItem.tagName : '-'
                    item.time = getYMD(item.createAt);
                })
                vm.scrapProbList = res.data.data;
                vm.historyPageOptions.total = res.data.total
                vm.historyPageOptions.pageNo = vm.historyPageNo;
            });
        },
        // 全部确认
        onclickSureBtn() {
            this.sureResponsibleModel = ''
            this.isShowSureModal = true
        },

        // 查看维护详情
        openImgModal(row) {
            this.selectItem = row
            this.isShowImgModal = true
        },

        onCancel() {
            this.editItem = {};
            this.singleItem = {};
            this.singleItem = {};
            this.isShowModal = false;
        },
        onConfirm() {
            const vm = this
            vm.loading = true
        },

        openImg(url) {
            window.open(url, '_blank')
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-warning-probe-list {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: @blank;
    position: relative;
    padding: 100px 0 70px;
    font-size: 16px;
    color: @textColor9;
    padding-left: 160px;
    * {
        box-sizing: border-box;
    }
    .header {
        position: absolute;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: space-between;
        top: 0;
        line-height: 60px;
        padding: 20px 20px 0 20px;
        padding-left: 180px;
        z-index: 5;
        background: @blank;
        .title {
            color: @textColor10;
            font-size: 24px;
            font-weight: bold;
        }
        .el-form-item__label {
            padding-right: 7px;
        }
        .header-right {
            font-size: 14px;
            .btn {
                height: 30px;
                line-height: 30px;
                width: 90px;
                text-align: center;
                border: 1px solid @textColor9;
                border-radius: 4px;
                display: inline-block;
                cursor: pointer;
                &.add-record {
                    background: @textColor3;
                    color: @blank;
                    border-color: @textColor3;
                    margin-left: 20px;
                }
            }
        }
    }
    .footer {
        height: 60px;
        padding: 10px 20px 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 2px -3px 4px #bbb;
        padding-left: 180px;
        text-align: right;
        img {
            height: 35px;
        }
    }
    .content {
        padding: 170px 20px 80px;
        height: 100%;
        overflow-y: auto;
        &.max-height {
            padding-top: 200px;
            .filter-box {
                height: 200px;
            }
        }

        .filter-box {
            padding: 10px 20px;
            position: fixed;
            left: 0px;
            right: 0px;
            z-index: 5;
            top: 70px;
            background: @blank;
            box-shadow: -2px 3px 4px #bbb;
            height: 180px;
            padding-left: 180px;

            .header-tab {
                line-height: 40px;
                padding: 0 15px;
                margin-bottom: 10px;
                .check-count-list {
                    display: flex;
                    .list-item {
                        padding: 10px;
                        border: 1px solid #bbb;
                        margin-right: 10px;
                        line-height: 30px;
                        .item-title {
                            line-height: 25px;
                            font-size: 20px;
                            font-weight: bold;
                        }

                        span {
                            display: inline-block;
                            width: 46%;
                            line-height: 25px;
                        }
                    }
                }
                .right-btn {
                    position: absolute;
                    right: 15px;
                    top: 0;
                    width: 100%;
                    text-align: right;
                    .warning-probe-btn {
                        padding: 0 15px;
                        border: 1px solid #515151;
                        border-radius: 5px;
                        line-height: 30px;
                        cursor: pointer;
                        color: #515151;
                        display: inline-block;
                        margin-left: 10px;
                        &.clear-warning-btn {
                            background: #209A56;
                            color: #fff;
                            border-color: #209A56;
                        }
                    }
                }
            }
        }
        .el-input-group__append {
            padding: 0 20px;
            cursor: pointer;
            background: @textColor3;
            color: @blank;
            border: 1px solid @textColor3;
        }
        .pl-10 {
            .el-input__inner[type="text"] {
                padding-left: 10px;
            }
        }
        .el-pagination {
            padding-top: 30px;
            text-align: center;
        }
    }
    .el-dialog {
        margin: 0 auto;
        .warning-tip {
            text-decoration: underline;
            color: @textColor2;
            cursor: pointer;
        }

        .select-model-item {
            width: 200px;
            line-height: 50px;
            margin: 20px auto;
            border: 1px solid #bbb;
            color: #000;
            text-align: center;
            font-size: 24px;
            cursor: pointer;
            &.active {
                color: #209A56;
                border-color: #209A56;
            }
        }

        .img-modal-body {
            .modal-header span{
                color: red;
                font-size: 24px;
                padding-right: 20px;
            }
            .model-img-list {
                padding-top: 20px;
                img {
                    width: 23.5%;
                    min-height: 20%;
                    display: inline-block;
                    background: fade(#eee, 20);
                    margin-right: 1.5%;
                    cursor: pointer;
                    max-height: 200px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
.van-dialog {
    z-index: 99999;
}
</style>


